/* eslint-disable */
import React from 'react';

import { getConfig } from '@edx/frontend-platform';
import { useIntl } from '@edx/frontend-platform/i18n';
import { Hyperlink, Image } from '@edx/paragon';
import classNames from 'classnames';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

import messages from './messages';

const LargeLayout = () => {
    const { formatMessage } = useIntl();

    return (
        <Row className="r4c_login_left_container">
            <Col>
                <Hyperlink destination={getConfig().MARKETING_SITE_BASE_URL}>
                    <Image className="r4c_logo" alt={getConfig().SITE_NAME} src={getConfig().LOGO_WHITE_URL} />
                </Hyperlink>
                <h1 className="r4c_login_header">Start learning with RES4CITY</h1>
                <p className="r4c_login_p">100% free. No credit card needed.</p>
            </Col>
        </Row>
    );
};

export default LargeLayout;
